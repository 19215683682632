.enabled-disabled-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .enabled {
        width: 20px;
        height: 20px;
        color: #40cc33;
    }
    .disabled {
        width: 20px;
        height: 20px;
        color: #ff0000;
    }
    .running {
        color: #40cc33;
    }
    .stopped {
        color: #ff0000;
    }
}
.first-column {
    margin: 0px;
}
  .app-status-header-lbl-row {
    height: 19px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
    float: center;
  }
