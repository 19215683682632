.need-access-container {
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
.need-access-btn {
    width: 229px;
    height: 40px;
    padding: 9px 33px 8px;
    border-radius: 4px;
    background-color: #ff7000;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 20px 0 0px 25px;;
}
.need-access-icon {
    width: 21px;
    height: 21px;
    margin-right: 26px;
}