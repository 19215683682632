.widget-main {
  .widget-container {
    margin-left: 40px;
    width: 340px;
    height: 120px;
    border-radius: 8px;
    box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.12), -3px -4px 5px 4px #fff;
    background-color: #f5f5f5;
  }

  .ant-card-head-title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }

  .labeled-text {
    width: 34px;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    color: #3b3b3b !important;
  }

  .unlabeled-text {
    width: 34px;
    height: 24px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    color: #3399ff !important;
  }

  .ant-card-small>.ant-card-body {
    padding: 0;
  }
}