.access-card-shadow {
  border-radius: 8px !important;
  // box-shadow: 0 0 29px 0 rgba(41, 182, 246, 0.16) !important;
}

.access-card {
  min-width: 270px;
  width: 100%;
  min-height: 240px;
  max-width: 370px;
  max-height: 300px;
  border-radius: 8px;
  border: solid 1px #e9e9e9;

  .anticon-close-circle > svg {
    width: 56px;
    height: 56px;
    color: #ff0000;
  }
  .anticon-check-circle > svg {
    width: 56px;
    height: 56px;
    color: #0080ff;
  }
  .access-card-desc {
    text-align: center;
    margin: auto;
    // height: 44px;
  }
  .access-card-title {
    padding-top: 10px;
    padding-bottom: 15px;
    position: relative;
    .access-tick {
      float: right;
      position: absolute;
      top: -10px;
      right: -10px;
      svg {
        width: 28px;
        height: 28px;
        background-color: #0080ff;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      }
    }
    .app-name {
      width: 113px;
      height: 21px;
           font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
  .access-info {
    width: 231px;
    height: 38px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #979797;
  }
  .access-type {
    width: 90px;
    height: 19px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #979797;
  }
  .divider {
    max-width: 271px;
    width: 100%;
    min-width: 180px;
    height: 1px;
    border: solid 1px #e9e9e9;
    margin-left: auto;
    margin-right: auto;
  }
  .access-list {
    margin: auto;
    // display: -webkit-inline-box;
    border-radius: 3px;
    border: solid 1px #e1e1e1;
    max-width: 275px;
    width: 100%;
    min-width: 200px;
    height: 32px;
    display: flex;
    .access-button-divder {
      width: 1px;
      height: 20px;
      border: solid 0.5px #e1e1e1;
      top: 0px;
    }
    .access-button {
      flex: 1;
      height: 30px;
      border: 0px;
      padding-top: 3px;
      cursor: pointer;
      .access-button-label {
        width: 47px;
        height: 19px;
               font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #979797;
      }
    }
    .access-button-selected {
      border-radius: 4px;
      background-color: #0080ff;
      border: 1px;
      height: 31px;
    }
    .access-button-label-selected {
      color: #ffff !important;
    }
    .ant-divider-vertical {
      margin: 0px;
    }
  }

  @media screen and (max-width: 231px) {
    .access-info {
      font: 14px;
    }
  }
}
