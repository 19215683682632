.filter-container {
    .button-filter {
      width: 96px;
      height: 32px;
      border-radius: 2px;
      background-color: #0080ff;
      border: none;
           font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      &:hover {
        background-color: #66b3ff;
      }
    }
  }
  
  .ant-drawer-content {
    .ant-drawer-body {
      padding: 61px 32px;
      // padding: 0;
        .filter-content {
          padding: 0;
          position: relative;
  
          .head-row {
            margin-bottom: 40px;
          }
  
          .filter-row {
            margin-bottom: 20px;
          }
  
          .filter-txt {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7d7d7d;
  
            svg {
              font-size: 15px;
            }
          }
          .reset-txt {
              padding-top: 2px;
            float: right;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ff7000;
            cursor: pointer;
          }
  
          .statistics-filter-label {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ff7000;
            margin-bottom: 5px;
          }

          .statistics-filter-duration-content {
            display: flex;
            flex-direction: column; 
            width: 100%; 
            // padding: 0px 10px;
          }

          .statistics-filter-duration-title {
            // font-weight: 500;
            // color: #3d3d3d;

            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ff7000;
            margin-bottom: 5px;
          }
  
          .filter-input {
            height: 32px;
            border-radius: 2px;
            border: solid 1px #b3b3b3;
          }
         
        }
      }
  
      .ant-drawer-footer {
        width: 100%;
        margin: 0;
        padding: 0 32px 80px 32px;
        bottom: 0;
  
        .apply-row {
          padding: 0 11px;
          margin-bottom: 11px;
          button {
            width: 240px;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            border-radius: 4px;
            background-color: #ff7000;
          }
        }
  
        .cancel-row {
          padding: 0 11px;
          button {
            width: 240px;
            height: 40px;
            border-radius: 4px;
            border: solid 1px #ff7000;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ff7000;
          }
        }
      }
    }
  
  