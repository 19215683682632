.access-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .container-box {
    width: 100%;
    flex: 1;
    .slick-slider {
      button {
        display: none !important;
      }
      .slick-list {
        overflow: hidden;
        .slick-slide {
          text-align: center;
          overflow: hidden;
        }
      }
    }
  }
  .hpl2-Logo__signet {
    width: 51px;
    height: 51px;
  }
  .hpl2-Logo__topline {
    width: 120px;
    height: 19px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }
  .hpl2-Logo__label {
    width: 141px;
    height: 28px;
       font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }
  .access-request-button {
    width: 240px;
    height: 40px;
    border-radius: 4px;
    background-color: #ff7000;
    float: right;
    .access-request-button-label {
      width: 114px;
      height: 19px;
           font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
  .access-request-label {
    width: 172px;
    height: 28px;
       font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ff7000;
  }
  .cards-container {
    margin: 0;
    padding: 14px;
    height: inherit;
  }
  .user-select {
    width: 320px;
    margin-left: 10px;    
  }
}
