.login-button-ms {
    height: 48px !important;
    width: 260px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    color: #ff7000 !important;
    border: solid 1.5px #ff7000 !important;
       font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    outline: none; 
  }
  .ms-logo {
    width: 21px;
    height: 21px;
    margin: 0 16px 0 0;
    object-fit: contain;
  }
  .lang-btn-container {
    display: flex;
    width: max-content;
    height: max-content;
    margin-left: 29px;
    margin-bottom: 20px;
  }
  .lang-btn-active {
    width: 34px;
    height: 26px;
       font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 2px;
    background-color: #ff7000;
    outline: none;
  }
  .lang-btn-inactive {
    width: 34px;
    height: 26px;
       font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 2px;
    background-color: #262626;
    outline: none;
  }