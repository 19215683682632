.oops {
  //   width: 94px;
  height: 42px;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b3b3b;
}

.sentence {
  //   width: 428px;
  height: 24px;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b3b3b;
}

.back-to-apps-btn {
  margin-top: 40px;
  width: 170px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px #ff7000 !important;
  .back-to-apps-btn-label {
    width: 130px;
    height: 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff7000;
  }
}
.back-to-apps-btn:active {
  border: solid 1px #1890ff !important;
}
.page-not-found-container {
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}
