.dmBtn-disabled {
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 20;
  width: 160px;
  height: 190px;
  object-fit: contain;
  outline: none;
  cursor: not-allowed;
}

.ant-input:hover{
    border-color: #ff7000 !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #ff7000 !important;
  border-right-width: 1px !important;
}
.ant-input:focus, .ant-input-focused {
 border-color: #ff7000 !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
   border-color: #ff7000 !important;
   border-right-width: 1px;
   outline: 0;
   -webkit-box-shadow:  0 2px 0 rgba(0, 0, 0, 0.045);
   box-shadow: none !important;
}