
.tb-header-lbl-first {
    height: 19px;
       font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
    margin-left: 60px;
  }
  .first-column {
      margin-left: 60px;
      margin-bottom: 0px;
  }
.tb-header-lbl-row {
    height: 19px;
       font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
    float: left;
  }
  // .ant-table-thead > tr > th {
  //   height: 50px;
  //   background-color: #e9e9e9;
  // }
//   th:first-child {
//     width: 500px;
//   }
//   th:last-child {
//     width: 200px;
// }