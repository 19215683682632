  .tb-main {
      .ant-table table {
          border-collapse: collapse !important;
      }

      .ant-table {
          background: inherit;
      }

      .ant-table-header {
          min-height: 54px;
          margin: 8px 8px 12px;
          padding: 5px 0px;
          border-radius: 8px;
          box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.3), -5px -3px 12px 1.5px #fff;
          background-color: #e9e9e9;
          color: #3b3b3b;
      }

      .ant-table-body {
          border-radius: 8px;
          box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, 0.1), 4px 1px 12px 9px #fff;
          margin: 8px 8px;
          padding: 18px 0px;
          overflow: hidden;
          min-height: 209px;
          background: #f8f8f8;
          color: #7d7d7d
      }

      .td-link {
          color: #66b3ff;
          cursor: pointer;
      }

      .deny-btn {
          width: 52px;
          height: 28px;
          border-radius: 2px;
          background-color: #e9e9e9;
          margin-right: 5px;
          padding: 0px;
      }

      .auth-btn {
          width: 81px;
          height: 28px;
          border-radius: 2px;
          background-color: #0080ff;
          margin-left: 5px;
          padding: 0px;
      }

      .ant-table-body::-webkit-scrollbar {
          width: 6px;
      }

      .ant-table-body::-webkit-scrollbar-thumb {
          border-radius: 12px;
          background-color: #e7e7e5;
      }

      .ant-checkbox-inner {
          border: 1px solid #ababab;
          background: inherit;
          border-radius: 3px;
      }

      .ant-table-cell {
          padding-left: 15px !important;
      }

      .ant-table-thead>tr>th {
          background: inherit;
          border-bottom: none;
      }

      .ant-table-tbody>tr.ant-table-row:hover {
          box-shadow: inset 2px 2px 2px 0 rgba(158, 158, 158, 0.12), inset -3px -3px 3px 0 #fff;
          margin: 16px 0 15px;
          padding: 16px 40px 17px 24px;
      }

      .ant-table-tbody>tr.ant-table-row:hover>td,
      .ant-table-tbody>tr>td.ant-table-cell-row-hover {
          background: inherit;
      }

      tr.ant-table-expanded-row>td {
          background: inherit;
      }


      .ant-pagination-item {
          background-color: inherit;
          border: unset;
      }

      .ant-pagination-disabled .ant-pagination-item-link,
      .ant-pagination-disabled:hover .ant-pagination-item-link {
          color: #198cff;
          border: unset;
          cursor: not-allowed;
          background: #f8f8f8;
      }

      .ant-pagination-next .ant-pagination-item-link,
      .ant-pagination-prev .ant-pagination-item-link {
          text-align: center;
          background-color: #f8f8f8;
          border: unset;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #ffffff;
          border-color: #198cff;
      }

      .ant-checkbox-inner::after {
          background-color: inherit;
          border-color: #198cff !important;
      }

      .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
          -webkit-box-shadow: unset;
          box-shadow: unset;
          z-index: 10;
      }
  }