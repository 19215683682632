.return-button {
  height: 45px;
  padding-bottom: 10px;
  z-index: 1;
  .back-button {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 2px #979797;
    outline: none;
  }
  .back-arrow {
    width: 14.4px;
    height: 17.7px;
    color: #979797;
    margin: -4px;
  }
  .header-one {
    width: 67px;
    height: 14px;
       font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
    margin-top: -32px;
    margin-left: 41px;
  }
  .header-two {
    height: 21px;
       font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
    margin-top: -13px;
    margin-left: 41px;
    width: 205px;
  }
}
