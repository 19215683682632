// .remove-modal {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
.modal-msg {
  width: 385px;
  height: 48px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7d7d7d;
}
.cancel-btn {
  width: 94px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #ff7000;
}
.remove-btn {
  width: 94px;
  height: 32px;
  border-radius: 4px;
  background-color: #ff7000;
}
.remove-btn:hover{
  background-color: #ff7000 !important;
} 

.btn-lbl {
  width: 54px;
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
