.sortiment-widget-main {
    .sortiment-widget-container {
        width: 240px;
        height: 120px;
        border-radius: 8px;
        box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.12), -3px -4px 5px 4px #fff;
        background-color: #f5f5f5;
        white-space: nowrap;
    }
    .ant-card-head-title {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color:  #515151;
    }
      .supplier-name {
               font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3b3b3b;      
    }
    .ant-card-small > .ant-card-body {
        padding: 0;
    }
    .ant-card-actions > li > span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}