.carousel-box {
  margin: 0 91px 0 0;
  background-color: #f5f5f5;

  .title {
    height: 28px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #b3b3b3;
  }

  .desc-text {
    max-width: 740px;
    height: 48px;
    margin: 10px 13.4px 36px 14px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    text-align-last: center;
    color: #515151;
  }

  .body {
    text-align: -webkit-center
  }

  .pom-img {
    width: 407px;
    height: 274px;
    margin: 0 38px 40px;
    object-fit: contain;
    margin-top: 88px
  }

  .mlp-img {
    width: 407px;
    height: 274px;
    margin: 0 38px 40px;
    object-fit: contain;
    margin-top: 88px
  }

  .pin-img {
    width: 407px;
    height: 274px;
    margin: 0 59px 40px;
    object-fit: contain;
    margin-top: 88px
  }

  .ant-carousel .slick-dots li button {
    width: 8px;
    height: 8px;
    margin: 0 10px;
    background-color: #b3b3b3;
    border-radius: 100%;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #ff7000ff;
    opacity: 1;
  }

  ul.slick-dots.slick-dots-bottom {
    margin-bottom: -26px;
  }
}