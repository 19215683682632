.roles-container {
  .role-name {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7d7d7d;
    height: 21px;
    .admin {
      width: 52px;
    }
    .user {
      width: 37px;
    }
  }
  .divider {
    height: 1px;
    border: solid 1px #e9e9e9;
  }
  .user-access-request {
    height: 21px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }
  .view-users-btn {
    width: 154px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px #ff7000;
    .view-users-lbl {
      width: 115px;
      height: 16px;
        font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ff7000;
    }
  }
  .roles-table-container {
    margin-top: 10px;
    display: block;
    flex-flow: row;
    // .ant-table-thead > tr > th {
    //   height: 50px;
    //   white-space: nowrap;
    // }
    // .ant-table-tbody > tr > td {
    //   padding: 10px 10px;
    //   height: 36px;
    //   white-space: nowrap;
    // }
    .ant-table-body::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    // .ant-table-body {
    //   -ms-overflow-style: none; /* IE and Edge */
    //   scrollbar-width: none; /* Firefox */
    // }
    .deny-btn {
      width: 52px;
      height: 28px;
      border-radius: 2px;
      background-color: #e9e9e9;
      margin-right: 5px;
      padding: 0px;
    }
    .auth-btn {
      width: 81px;
      height: 28px;
      border-radius: 2px;
      background-color: #0080ff;
      margin-left: 5px;
      padding: 0px;
    }
    .re-auth-btn {
      width: 143px;
      height: 28px;
      border-radius: 2px;
      background-color: #0080ff;
      margin-left: 5px;
      padding: 0px;
    }
    .lbl {
      height: 16px;
        font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
.tb-header-lbl {
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #515151;
  
}
.tb-application-comp {
  float: left
}
.role-email {
  height: 14px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #0080ff;
}
.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}
.ant-select-multiple .ant-select-selector {
  border: none;
}
