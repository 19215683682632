body,
code {
    font-family: 'Roboto', sans-serif !important;
}

.body-layout {
    width: 100%;
    padding: 35px 40px 40px 0;
}

.layout-bg {
    background-color: #f8f8f8 !important;
}

.content-layout {
    position: relative;
    min-height: 280px;
    max-height: 100vh;
    z-index: 1;
    overflow: auto;
    padding: 22px 45px 13px 65px;
    border-radius: 12px;
    box-shadow: 0 0 34px -12px #c7c3c3;
}

.flex-layout {
    display: flex;
    width: 100%;
}

.platform-container {
    .ant-row {
        display: block;
    }
}

.market-list-prices {
    .ant-row {
        display: flex;
    }
}

// .ssbi-manager {
//     .ant-row {
//         display: flex;
//     }
// }

.cat-container {
    .ant-row {
        display: block;
    }
}

.mg-tp-40 {
    margin-top: 40px;
}

.txt-algn-cntr {
    text-align: center;
}