  .report-btn {
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 20;
    width: 160px;
    height: auto;
    object-fit: contain;
    outline: none;
    .report-label {
        margin: auto;
        color: #7d7d7d;
        height: auto;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        overflow-wrap: break-word;
        margin-bottom: 10px;
      }
  }

  .report-btn:hover .report-label {
    color: #E6AE12,
  }
  
  .report-icon {
    max-width: 160px;
    max-height: 160px;
    min-width: 90px;
    min-height: 90px;
    width: 100% !important;
    height: auto !important;
    // box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.12), -4px -4px 12px 2px #ffffff;
  }
  