
.search-container {
    display: flex;
    position: relative;
}
.input-search {
    border-radius: 2px;
    border: solid 1px #b3b3b3;
}
.search-icon {
    top: 8px;
    left: 10px;
    position: absolute;
    width: 18px !important;
    height: 20px !important;
    color: #b3b3b3;
}
.filter {
    margin-left: 20px;
    width: 96px;
    height: 34px;
    border-radius: 2px;
    background-color: #66b3ff;
    cursor: pointer;
}
.filter-button {
    width: 96px;
    height: 32px;
    border-radius: 2px;
    background-color: #0080ff;
    border: none;
       font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .filter-button:hover,
  .filter-button:active,
  .filter-button:focus {
    background-color: #3399ff;
  }
.filter-icon {
    fill: white;
    margin-top: 10px;
    margin-left: 15px;
}
.text {
    width: 32px;
    height: 16px;
       font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: -22px;
    margin-left: 42px;
}
.statistics-back-btn {
    width: 5px;
    height: 30px;
    border-radius: 5px;
}