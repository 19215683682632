.report-redirect-btn {
    margin-left: 20px;
    margin-right: 5px;
    margin-top: 25;
    width: 112px;
    height: auto;
    object-fit: contain;
    outline: none;
  }

  .report-btn:hover .report-label {
    color: #E6AE12,
  }
  
  .report-icon {
    max-width: 160px;
    max-height: 160px;
    min-width: 90px;
    min-height: 90px;
    width: 100% !important;
    height: auto !important;
    // box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.12), -4px -4px 12px 2px #ffffff;
  }
  