.logout-modal{
  .ant-modal-body {
    min-width: 500px !important;
    min-height: 50px !important;
  }
  .idle-label {
    color: #7d7d7d !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

