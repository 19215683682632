.container {
  max-width: inherit;
  margin-top: 10px !important;
}
.appIcon {
  flex-grow: 1;
  margin-bottom: 32px;
}
.heading {
  font-size: 20px;
  height: 24px;
  margin-bottom: 0px !important;
  color: #30323a !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.app-icon-container{
  height: 100%;
  // margin-top: 16px;
}
.app-icon {
  width: 176px !important;
  height: 176px !important;
  margin-left: -24px;
}
.body {
  color: #fff !important;
}
.label {
  margin: auto;
  color: #7d7d7d;
  height: 32px;
  width: 100%;
  max-width: 66px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.dmBtn {
  // margin-left: 10px;
  margin-right: 60px;
  // margin-top: 16px;
  width: 112px;
  height: 100%;
  object-fit: contain;
  outline: none;
  // &:hover {
  //   background-color: #f4f4f4;
  // }
}
.back {
  background-color: "#dedede";
  min-width: 42;
  height: 42;
  color: black;
  border-radius: 15;
}
.next {
  background-color: #dedede;
  min-width: 42;
  height: 42;
  color: black;
  border-radius: 15;
}
.arrow {
  font-size: 20;
}
.loading {
  left: 50% !important;
  position: relative !important;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin-top: 25% !important;
}
.login-loading {
  top: 25% !important;
  left: 50%;
}
.disable-link {
  cursor: default !important;
}

.landingpage-card {
  // border-radius: 12px;
  // box-shadow: 0 0 34px -12px #c7c3c3;
  // background-color: #f5f5f5;
  height: 100%;
  width: 100%;
  // padding: 22px 45px 13px 59px;
}
.land-height{
  height: 100%;
  margin: auto;
}

@media screen {
  @media (min-width: 769px) and (max-width: 1500px) {
    .landingpage-card {
      // border-radius: 12px;
      // box-shadow: 0 0 34px -12px #c7c3c3;
      // background-color: #f5f5f5;
      height: 95%;
      width: 100%;
      // padding: 22px 45px 13px 59px;
    }
  }
}

.app-landingpage-card {
  border-radius: 12px;
  box-shadow: 0 0 34px -12px #c7c3c3;
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
  padding: 22px 45px 13px 59px;
}

@media screen {
  @media (min-width: 769px) and (max-width: 1500px) {
    .app-landingpage-card {
      border-radius: 12px;
      box-shadow: 0 0 34px -12px #c7c3c3;
      background-color: #f5f5f5;
      height: 100%;
      width: 100%;
      padding: 22px 45px 13px 59px;
    }
  }
}