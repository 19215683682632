.Rectangle {
  /* width: 220px; */
  max-height: 500px;
  border-radius: 3px;
  background-color: #f2f8ff;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
  background-color: #d8d8d8;
}

.tab-container {
  .ant-tabs {
    flex: auto;
  }
  .tab-label {
    height: 19px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7d7d7d;
    .role-label {
      width: 41px;
      height: 19px;
    }
    .devops-label {
      width: 129px;
      height: 19px;
    }
  }
  .ant-tabs-ink-bar {
    background-color: #ff7000;
  }
}
.settings-profile-name {
  margin-left: 10px !important;
  margin-top: 5px !important;
  color: #3b3b3b !important;
  padding: 15px 0px !important;
  width: 116px;
  height: 19px;
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.Roles {
  width: 40px;
  height: 19px;
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3399ff;
  padding: 20px;
}

.settings-role {
  padding-left: 20px;
  padding-bottom: 5%;
  padding-right: 20px;
   font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #515151;
}

.container {
  margin-top: 10px !important;
}

.cap-letter {
  position: relative;
  top: -10px;
}
.tab-label {
  height: 19px;
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7d7d7d;
}
.app-name{
 display: flex;
 flex-direction: row;
 align-items: center;
 .app-text{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #292828;
  margin-left: 10px;
 }
}
// .ant-table-body::-webkit-scrollbar {
//   display: none;
// }
/* Hide scrollbar for IE, Edge and Firefox */
// .ant-table-body {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }
