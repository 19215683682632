.login-component {
  display: flex;
  flex-direction: row;
  height: 100%;
  .carousel {
    min-height: 800px;
    height: 100vh;
    width: 60.2%;
    margin: 0;
    padding: 168px 149.6px 156px 149px;
    background-color: #f5f5f5;
  }
  .login-paper {
    align-items: center;
    margin: auto;
    display: flex !important;
    width: 40.8% !important;
  }
  .login-box {
    display: flex !important;
    width: 40.8% !important;
  }
  .login-form {
    position: relative;
    flex: auto;
    height: fit-content;
    align-items: center;
    margin: auto;
    .login-logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      font-size: 18px;
      line-height: 1.5;
      font-weight: 600;
      height: 46px !important;
      padding-bottom: 10px !important;
      color: white;
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }
    .header {
      margin: auto;
      width: fit-content;
      margin-bottom: 40px;
      .login-heading {
        color: #ff7000 !important;
               font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }
      .sub-heading {
        width: 13.3%;
        height: 2.6%;
               font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #979797;
        display: table-cell;
        padding-top: 10px;
      }
    }
  }
  .login-logo {
    margin: auto;
    width: fit-content;
    margin-bottom: 32px;
  }
  .ds-logo {
    display: flex;
    align-items: center;
    text-align: left;
    outline: 0;
    color: black !important;
  }

  .hpl2-Logo__signet {
    width: 51px !important;
    height: 51px !important;
  }
  .hpl2-Logo__topline {
    display: block;
    width: 120px;
    height: 19px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }
  .hpl2-Logo__label {
    display: block;
    width: 141px;
    height: 28px;
       font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }
  .username {
    margin: auto;
    // width: fit-content;
    width: 100%;
    margin-bottom: 20px;
    max-width: 360px;
  }

  .login-button-holder {
    margin: auto;
    width: fit-content;
    padding-bottom: 5.2%;
  }
  .request-button {
    width: 240px;
    height: 40px;
    border-radius: 4px;
    background-color: #ff7000;
    .request-button-label {
      width: 171px;
      height: 19px;
           font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
  .request-button:hover,
  .request-button:focus,
  .request-button:active {
    background-color: rgba(255, 112, 0, 0.9) !important;
  }
  .login-button {
    margin: auto;
    height: 40px !important;
    width: fit-content;
    border-radius: 4px !important;
  }
  .forgetter {
    margin: auto;
    width: fit-content;
    margin-bottom: 10px !important;
  }
  .access {
    text-align: center;
    margin: auto;
    margin-top: -7px;
    max-width: 360px;
  }
  .or {
    text-align: center;
    color: #b3b3b3;
  }
  .mailer {
    margin-top: -37px;
  }
  .warning-icon {
    color: red;
  }
  .login-error {
    max-width: 360px;
    width: 100%;
    display: flex;
    margin: auto;
  }
  .error-text {
    display: flex;
    margin-top: -4px;
    margin-left: 10px;
    color: red;
  }

  .hpl2-InputField__label {
    transform: scale(0.75) translateY(-25%) !important;
  }
  .back-login,
  .forgot-password {
    width: fit-content;
    height: 2.1%;
       font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #b3b3b3;
  }

  .pageSlider-exit > .page {
    z-index: 1;
  }

  .pageSlider-enter > .page {
    z-index: 1;
  }

  .pageSlider-enter.left > .page {
    transform: translate3d(60%, 0, 0);
  }

  .pageSlider-enter.right > .page {
    transform: translate3d(-30%, 0, 0);
  }

  .pageSlider-exit.left > .page {
    transform: translate3d(-60%, 0, 0);
  }

  .pageSlider-exit.right > .page {
    transform: translate3d(30%, 0, 0);
  }

  .pageSlider-enter.pageSlider-enter-active > .page {
    transform: translate3d(0, 0, 0);
    transition: all 400ms linear;
  }
}
