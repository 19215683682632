.tbl-options {
  height: 19px;
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7d7d7d;
}
.ant-select-selection-item {
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7d7d7d;
}
.delete-icon {
  margin-right: 4px;
  margin-top: 10px;
}
