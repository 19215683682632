.statistics-container {
    display: flex;
    padding: 10px;
}
.statistics-card {
    min-width: 270px;
    width: 100%;
    max-width: 350px;
    max-height: 350px;
    border-radius: 30px;
    border: solid 1px #e9e9e9;
    margin: 0 auto;
    cursor: pointer;
}
.statistics-header {
    text-align: center;
    height: 75px;
}
.statistics-title {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    color: #ff7000;
}
.statistics-total {
    width: 34%;
    height: 100px;
    padding: 10px;
    text-align: center;
    border: solid 1px #e9e9e9;
    border-bottom-left-radius: 30px;
}
.statistics-internal {
    width: 34%;
    height: 100px;
    padding: 10px;
    text-align: center;
    border: solid 1px #e9e9e9;
}
.statistics-external {
    width: 34%;
    height: 100px;
    padding: 10px;
    text-align: center;
    border: solid 1px #e9e9e9;
    border-bottom-right-radius: 30px;
}
.statistics-content {
    display: flex;
}
.statistics-heading {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #7d7d7d;
}
.statistics-values {
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7d7d7d !important;
    margin: 10px 0;
}
.statistics-data-container {
    display: flex;
}