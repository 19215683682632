.grid-container {
  display: flex;

  grid-template-columns: 20% 1fr;
  grid-template-rows: 72px 1fr;

  grid-template-areas:
    "sidebar head head head"
    "sidebar main main main"
    "sidebar main main main"
    "sidebar main main main";

  /* grid-container height = 100% of viewport height */
  height: 100vh;
}
.grid-sidebar {
  grid-area: sidebar;
}
.header {
  grid-area: head;
}
.content {
  grid-area: main;
  min-width: 700px;
  min-height: 600px;
  margin-left: 30px;
}
.main-menu {
  margin-top: 39px !important;
}
.open-button {
  transition: margin .2s linear;
  margin-left: 102px;
  width: 35px !important;
  height: 35px !important;
  pointer-events: auto;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 44%;
}
.close-button {
  transition: margin .2s linear;
  margin-left: 227px;
  width: 34px !important;
  height: 34px !important;
  pointer-events: auto;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 44%;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  height: 40px !important;
  margin-left: 41px !important;
  color: #30323a;
  transition: margin .2s linear;
}
.logo-collapsed {
  margin-left: 30px !important;
  transition: margin .2s linear;
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}
.ant-menu-item-selected {
  
  color: #198cff !important;
  background-color: #f5f5f5 !important;
}
.ant-menu-inline .ant-menu-item::after {
  border-left: 6px solid #198cff !important;

  width: 244px !important;
  border-right: 0px !important;
}
.sider-layout{
  padding-left: 0px;
  height: 100%;
}
.ant-layout-sider {
  flex: none !important;
  max-width: 244px !important;
  min-width: 85px !important;
  width: 244px !important;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  width: 244px;
  display: flex;
  flex-direction: column;
}
.ant-menu-inline {
  border-right: 0px !important;
  width: 244px !important;
  width: 100%;
}
.ant-menu-item {
  padding-left: 37px !important;
}
.ant-menu-inline-collapsed {
  width: 85px !important;
}
.icon-item {
  width: 25px !important;
  height: 25px !important;
  pointer-events: auto;
  position: absolute;
  margin-top: 5px;
}

.menu-label {
  color: #7d7d7d !important;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1.3;
  text-align: left;
  padding: 15px 0px;
  margin-left: 60px;
  margin-bottom: 5px;
  pointer-events: auto;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px !important;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #198cff !important;
  font-weight: 500 !important;
}
.ant-menu-item:hover {
  background-color: #e6f7ff !important;
}
.second-menu {
  /* position: absolute; */
  bottom: 35px;
  width: 244px;
}
.ant-layout-sider-collapsed {
  width: 120px !important;
}
.Avatar {
  height: 50px !important;
  padding-left: 30px !important;
  align-items: center;
}

.profile-name {
  margin-left: 20px !important;
  margin-top: 5px !important;
  color: #fff !important;
  padding: 15px 0px !important;
  width: 116px;
  height: 19px;
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.profile-text {
  width: 35px;
  height: 14px;
   font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
  margin-left: -110px;
  margin-bottom: -30px;
}
.logo-label {
  color: "#30323a";
}
.logo-label-collapsed {
  color: "#30323a";
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.logout-icon {
  width: 25px !important;
  height: 25px !important;
  font-size: 16pt;
  font-weight: 400;
  color: #7d7d7d !important;
  margin-left: 8px;
}
.footer-label {
  margin-top: 5px !important;
  color: #7d7d7d !important;
  width: 116px;
  height: 19px;
   font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: -webkit-baseline-middle;
  cursor: pointer;
}

.footer-menu {
  height: 50px !important;
  align-content: center;
  padding-left: 29px;
  cursor: pointer;
}
.footer-menu:hover,
.footer-menu:active,
.footer-menu:focus {
  background-color: #e6f7ff !important;
}
.footer-menu-collapsed {
  height: 50px !important;
  align-content: center;
  padding-left: 29px;
  width: 85px !important;
  cursor: pointer;
}
.footer-menu-collapsed:hover,
.footer-menu-collapsed:active,
.footer-menu-collapsed:focus {
  background-color: #545454 !important;
  width: 85px !important;
  cursor: pointer;
}
.footer-label-collapsed {
  display: none;
}
.lang-btn-container {
  display: flex;
  width: max-content;
  height: max-content;
  margin-left: 29px;
  margin-bottom: 20px;
}
.lang-btn-container-collapsed {
  display: flex;
  height: max-content;
  margin-left: 29px;
  margin-bottom: 20px;
}
.lang-btn-active {
  width: 34px;
  height: 26px;
   font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 2px;
  background-color: #198cff;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 5px;
}
.lang-btn-inactive {
  width: 34px;
  height: 26px;
   font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 2px;
  background-color: #d8d8d8;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 5px;
}