.success-window {
  display: grid;
  margin: auto;
  width: 50%;
  .hpl2-Logo__signet {
    width: 51px;
    height: 51px;
  }
  .hpl2-Logo__topline {
    width: 120px;
    height: 19px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }
  .hpl2-Logo__label {
    width: 141px;
    height: 28px;
       font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #515151;
  }

  .success-msg {
    width: 166px;
    height: 38px;
       font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7d7d7d;
  }
  .back-btn {
    width: 208px;
    height: 34px;
    border-radius: 2px;
    border: solid 1px #ff7000;
    .btn-label {
      width: 159px;
      height: 16px;
           font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ff7000;
    }
  }
}
